<template>
  <div class="wifi">
    <div class="left">
      <p class="left-title">WiFi配置：</p>
      <el-form :model="wifiData" :rules="rules" ref="formRef" inline>
        <li v-for="(item, index) in wifiData.wifiList" :key="index">
          <el-form-item label="wifi名称" :prop="'wifiList.' + index + '.wifiName'" :rules="rules.wifiName">
            <el-input v-model.trim="item.wifiName" maxlength="16"></el-input>
          </el-form-item>
          <el-form-item label="wifi密码" :prop="'wifiList.' + index + '.wifiPassword'" :rules="rules.wifiPassword">
            <el-input v-model.trim="item.wifiPassword" maxlength="16"></el-input>
          </el-form-item>
          <el-button @click.prevent="onWifiDel(item)">删除</el-button>
        </li>
        <div class="wifi-footer">
          <el-button @click="onWifiAdd()">新增WiFi</el-button>
          <el-button type="primary" v-if="wifiData.wifiList.length" @click="onWifiSave('formRef')">保存</el-button>
        </div>
      </el-form>
    </div>
    <div class="right">
      <p>小程序展示样式</p>
      <img src="~@/assets/images/mini/wifi.png" alt="">
    </div>
  </div>
</template>

<script>
import {
  getWifiList,
  getWifiEdit,
} from "@/api/shop/mini";
import { rules } from "@/db/rules";
export default {
  data() {
    return {
      rules,
      params: {
        shopId: "",//店铺ID
        adId: "", //轮播ID
        adPic: "", //图片id集合
      },
      wifiData: {
        wifiList: [
          {
            wifiName: "",
            wifiPassword: "",
          }
        ]
      },
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem('playOne-store-merchantShopId');
    if (merchantShopId) {
      this.params.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getWifiList();
  },
  methods: {
    // 【请求】小程序wifi列表
    getWifiList() {
      let data = {
        shopId: this.params.shopId
      };
      getWifiList(data).then(res => {
        if (res.isSuccess == "yes") {
          if (res.data) {
            this.wifiData.wifiList = res.data;
          }
        }
      })
    },

    // 【请求】小程序wifi编辑
    getWifiEdit() {
      let data = this.wifiData.wifiList
      getWifiEdit(data).then(res => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.getWifiList();
        }
      })
    },

    // 【监听】保存wifi信息
    onWifiSave(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.getWifiEdit();
        }
      })
    },

    // 【监听】wifi删除
    onWifiDel(item) {
      var index = this.wifiData.wifiList.indexOf(item)
      if (index !== -1) {
        this.wifiData.wifiList.splice(index, 1)
      }
    },

    // 【监听】新增WiFi
    onWifiAdd() {
      let obj = {
        shopId: this.params.shopId,
        wifiName: "",
        wifiPassword: "",
      }
      this.wifiData.wifiList.push(obj)
    },
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/theme';

.wifi {
  display: flex;
}

.foot-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .1rem;
}

.left {
  width: 60%;
}

.left-title {
  margin-bottom: .15rem;

  span {
    font-size: .14rem;
    color: #999999;
  }
}

.right {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: .1rem 0;
  }
}
</style>


